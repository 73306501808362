import { useMemo } from "react";
import { IfxPrivilegeName } from "../constants/types";
import { EnhancedUser, useAuth } from "../contexts/authUser";

const DEFAULT_USER_PERMISSIONS = {
  canAddUser: false,
  canAssignContent: false,
  canManageCompany: false,
  canManageUserPrivileges: false,
  canManageUsers: false,
  canViewReporting: false,
};
Object.freeze(DEFAULT_USER_PERMISSIONS);

export type UserPermissions = typeof DEFAULT_USER_PERMISSIONS;
export type UserPermission = keyof UserPermissions;

function permissionsForPrivileges(privileges: string[]) {
  if (!privileges.length) return DEFAULT_USER_PERMISSIONS;
  privileges as IfxPrivilegeName[];

  if (privileges.includes("IFXADMIN")) {
    const superUserPermissions = Object.fromEntries(
      Object.keys(DEFAULT_USER_PERMISSIONS).map(permission => [permission, true])
    );
    Object.freeze(superUserPermissions);
    return superUserPermissions as UserPermissions;
  }

  const isAdmin = privileges.includes("ADMIN");
  const isHrBusinessPartner = privileges.includes("HRBP");

  /*
  const isContentAdministrator = privileges.includes("CONTENT");
  */

  const userPermissions = {
    canAddUser: isAdmin || isHrBusinessPartner,
    canAssignContent: isAdmin || isHrBusinessPartner,
    canManageCompany: isAdmin || isHrBusinessPartner,
    canManageUserPrivileges: isAdmin,
    canManageUsers: isAdmin || isHrBusinessPartner,
    canViewReporting: isAdmin || isHrBusinessPartner,
  };

  Object.freeze(userPermissions);

  return userPermissions;
}

export function hasPermission(
  userPermissions: UserPermissions,
  requiredPermissions: UserPermission[]
) {
  // Every permission provided as required needs to be present and true on userPermissions
  return requiredPermissions.every(permission => userPermissions[permission]);
}

export function useUserPermissions(orgUnitId?: string) {
  const { authUser } = useAuth();

  const { privileges, privilegeMap } =
    authUser ??
    ({
      privileges: [],
      privilegeMap: {},
    } as Pick<EnhancedUser, "privileges" | "privilegeMap">);

  return useMemo(() => {
    if (orgUnitId) {
      // orgUnit is specified but this user has no access to that orgUnit
      if (!(orgUnitId in privilegeMap)) return DEFAULT_USER_PERMISSIONS;

      const orgUnitPrivileges = privilegeMap[orgUnitId];
      return permissionsForPrivileges(
        Array.from(new Set(orgUnitPrivileges.map(priv => priv.privilegeName)))
      );
    }

    return permissionsForPrivileges(privileges);
  }, [privileges, privilegeMap, orgUnitId]);
}

export function useHasPermission(requiredPermissions: UserPermission[], orgUnitId?: string) {
  const permissions = useUserPermissions(orgUnitId);
  return hasPermission(permissions, requiredPermissions);
}
