import React, { useEffect, useState } from "react";
import { Button, Progress } from "reactstrap";
import firebase from "../../../contexts/firebase";
import { ifxApiClient } from "../../../helpers/api";
import { EMBED_STAGE } from "../../../views/Pages/Embed/common";
// import { browser } from "@tensorflow/tfjs";

const AnalysisCard = ({ children }: { children: React.ReactNode }) => (
  <div
    style={{
      padding: "10px",
      boxShadow: "0px 0px 5px 4px rgba(0,0,0,0.1)",
      borderRadius: "10px",
      margin: "20px 0",
    }}
  >
    {children}
  </div>
);
 
// TODO: change this any to unknown and use guards to narrow the type safely
const getAbilityPoints = (abilityId: string, abilities: any[]) => {
  const currentAbility = abilities.find(ability => ability.ability_id === abilityId);

  return currentAbility.score * currentAbility.weight * 100;
};

const GRADED_ABILITIES = ["Themes", "Talking Points", "Key Phrases"];

interface AbilityFeedback {
  feedback: string;
  isPositive: boolean;
}

// Logic from the mobile app.
const getAbilityFeedback = (
  name: string,
  points: number,
  feedback?: string[] | string,
  feedbackExtra?: string[] | string
): AbilityFeedback[] => {
  const finalFeedbackList: AbilityFeedback[] = [];

  let feedbackList: string[] = [];
  if (feedback) {
    feedbackList = typeof feedback === "string" ? [feedback] : feedback;
  }

  let extraFeedbackList: string[] = [];
  if (feedbackExtra) {
    extraFeedbackList = typeof feedbackExtra === "string" ? [feedbackExtra] : feedbackExtra;
  }

  if (GRADED_ABILITIES.indexOf(name) < 0) {
    feedbackList.forEach(f => {
      finalFeedbackList.push({
        feedback: f,
        isPositive: points > 0,
      });
    });
  } else if (points > 0 && feedbackList.length > 0 && extraFeedbackList.length > 0) {
    feedbackList.forEach(f => {
      finalFeedbackList.push({
        feedback: f,
        isPositive: true,
      });
    });
    extraFeedbackList.forEach(f => {
      finalFeedbackList.push({
        feedback: f,
        isPositive: false,
      });
    });
  } else if (points === 0 && feedbackList.length > 0 && extraFeedbackList.length > 0) {
    extraFeedbackList.forEach(f => {
      finalFeedbackList.push({
        feedback: f,
        isPositive: true,
      });
    });
    feedbackList.forEach(f => {
      finalFeedbackList.push({
        feedback: f,
        isPositive: false,
      });
    });
  } else {
    feedbackList.forEach(f => {
      finalFeedbackList.push({
        feedback: f,
        isPositive: points > 0,
      });
    });
  }

  return finalFeedbackList;
};

const extractAbilities = (analysisResponse: {
  analyses: any[];
  // TODO: fix types
  skills: { current: { abilities: any[] } };
}) =>
  analysisResponse.analyses.map(analysis => {
    const abilityPoints = getAbilityPoints(
      analysis.ability_id,
      analysisResponse.skills.current.abilities
    );

    const abilityFeedback = getAbilityFeedback(
      analysis.app_title,
      abilityPoints,
      analysis.results.feedback,
      analysis.results.feedback_extra
    );

    return {
      name: analysis.app_title,
      description: analysis.app_description,
      priority: analysis.priority,
      numRequired: analysis.results.required ? analysis.results.required : 0,
      points: abilityPoints,
      feedback: abilityFeedback,
    };
  });

const getScoreText = (score: number) => {
  if (score < 0.33) {
    return (
      <h5 className="text-danger" style={{ textAlign: "center" }}>
        Needs improvement
      </h5>
    );
  } else if (score < 0.66) {
    return (
      <h5 className="text-warning" style={{ textAlign: "center" }}>
        Almost there
      </h5>
    );
  } else {
    return (
      <h5 className="text-success" style={{ textAlign: "center" }}>
        Well done!
      </h5>
    );
  }
};

function getAbilityStatusText(
  title: string,
  priority: number,
  numTotal: number,
  numRequired: number,
  maxRequiredPriority = 2
) {
  if (priority > maxRequiredPriority) {
    return <p>Extra credit</p>;
  }

  if (GRADED_ABILITIES.indexOf(title) < 0) {
    return <p>Required to pass</p>;
  }

  return (
    <p>
      Must discuss {numRequired ? numRequired : "?"} of {numTotal} to pass
    </p>
  );
}

const Column = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      style={{
        width: "350px",
        overflow: "hidden",
        padding: "10px",
        minWidth: "200px",
        margin: "0 10px",
      }}
    >
      {children}
    </div>
  );
};

function detectBrowserAndOS() {
  const userAgent = navigator.userAgent;
  // console.log('userAgent: ', userAgent);
  
  let browser = "Unknown";
  let os = "Unknown";

  // Detect browser
  if (userAgent.indexOf("Firefox") > -1) {
      browser = "Firefox";
  } else if (userAgent.indexOf("SamsungBrowser") > -1) {
      browser = "Samsung Browser";
  } else if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
      browser = "Opera";
  } else if (userAgent.indexOf("Trident") > -1) {
      browser = "Internet Explorer";
  } else if (userAgent.indexOf("Edge") > -1) {
      browser = "Microsoft Edge (Legacy)";
  } else if (userAgent.indexOf("Edg") > -1) {
      browser = "Microsoft Edge (Chromium)";
  } else if (userAgent.indexOf("Chrome") > -1) {
      browser = "Chrome";
  } else if (userAgent.indexOf("Safari") > -1) {
      browser = "Safari";
  }

  // Detect OS
  if (userAgent.indexOf("Win") > -1) {
      os = "Windows";
  } else if (userAgent.indexOf("Mac") > -1) {
      os = "macOS";
  } else if (userAgent.indexOf("Linux") > -1) {
      os = "Linux";
  } else if (userAgent.indexOf("Android") > -1) {
      os = "Android";
  } else if (userAgent.indexOf("like Mac") > -1) {
      os = "iOS";
  }

  return { browser, os };
}

interface AnalysisScreenProps {
  videoUrl?: string;
  storyId: string;
  sceneId: string;
  userId: string;
  onStageChange: (stage: EMBED_STAGE | null) => void;
  onAnalysisResponse?: (analysisResponse: any) => void;
  analysisResponse?: any;
  isEmbedded?: boolean;
}

const AnalysisScreen = ({
  videoUrl,
  onStageChange,
  sceneId,
  storyId,
  userId,
  onAnalysisResponse,
  analysisResponse: upstreamAnalysisResponse,
  isEmbedded = false,
}: AnalysisScreenProps) => {
  const [analysisResponse, setAnalysisResponse] = useState<{ response?: any; error?: boolean }>({
    response: upstreamAnalysisResponse || null,
    error: undefined,
  });

  console.log("IFX: Entered AnalysisScreen:", analysisResponse);

  // TODO: use shared color
  const headerColor = "#32afd9";

  useEffect(() => {
    if (analysisResponse.response || analysisResponse.error) {
      return;
    }
    const browserAndOS = detectBrowserAndOS();
    console.log("********************************************");
    console.log("IFX:  New AnalysisScreen about to post content to analysis/analyze/embed ");
    console.log("St, Sc, uid, ", storyId, sceneId, userId)
    console.log("vid, br, os", videoUrl, browserAndOS.browser, browserAndOS.os);
    // console.log("IFX:  AnalysisScreen detected browser and OS as ", browserAndOS);
    // ifxApiClient.post(`/content/${sceneId}/analyze/v2?method=upload`, {
    //   contentId: sceneId,
    //   contentGuid: sceneId,
    //   user: userId,
    //   uri: videoUrl,
    //   storyid: storyId,
    //   webBrowser: browserAndOS.browser,
    //   os: browserAndOS.os,
    // })

    const utterancePayload = {
      uid: userId,
      storyid: storyId,
      sceneid: sceneId,
      uri: videoUrl,
      localfilename: null,
      localaudiofilename: null,
      method: "web",
      webBrowser: browserAndOS.browser,
      os: browserAndOS.os,
      };

    console.log("utterancePayload:", utterancePayload);
    // console.info("current user : ", firebase.auth.currentUser?.uid);
    // const thisusertoken =  await firebase.auth.currentUser?.getIdToken();
    // console.info("current user token: ", thisusertoken);

  ifxApiClient.post("/analysis/analyze/embed", utterancePayload)
      .then((response: { data: any }) => {
        if (!response?.data.analyses) {
          setAnalysisResponse({ error: true, response: undefined });
        } else {
          setAnalysisResponse({ response: response.data, error: undefined });
          onAnalysisResponse?.(response.data);
        }
      })
      .catch(err => setAnalysisResponse({ error: true, response: undefined }));
  }, [analysisResponse, onAnalysisResponse, sceneId, storyId, userId, videoUrl]);

  let content = null;

  if (analysisResponse.error) {
    console.error("IFX: AnalysisScreen Error analyzing the utterance", analysisResponse.error);
    content = (
      <div
        style={{
          marginTop: "100px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        <Column>
          <h5
            style={{
              textAlign: "center",
            }}
          >
            We didn't hear anyone talking.
          </h5>
          <h5
            style={{
              textAlign: "center",
            }}
          >
            Please be sure to speak up.
          </h5>

          <div>
            <Button
              style={{ marginBottom: "10px", marginTop: "100px", color: "white" }}
              block
              color={"info"}
              onClick={() => {
                onAnalysisResponse?.(null);
                onStageChange(EMBED_STAGE.RESPONSE_VIDEO_RECORDING);
              }}
            >
              Record again
            </Button>
          </div>
        </Column>
      </div>
    );
  } else if (!analysisResponse.response) {
    content = (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        <h4>Analyzing…</h4>
      </div>
    );
  } else {
    const parsedAbilities = extractAbilities(analysisResponse.response);
    const score = analysisResponse?.response?.skills?.current.score || 0;

    content = (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        <Column>
          <h3 style={{ color: headerColor }}>Skill Progress</h3>
          <AnalysisCard>
            <h4 style={{ color: headerColor }}>{analysisResponse?.response?.skills?.name}</h4>

            <h2 style={{ color: headerColor, textAlign: "center" }}>
              {analysisResponse?.response?.skills?.current.score * 100}
            </h2>
            <Progress value={score * 100} />

            <div style={{ marginTop: "20px" }}>{getScoreText(score)}</div>
          </AnalysisCard>

          <div>
            <Button
              // TODO: these styles are shared, could be extracted to a constant.
              style={{ marginBottom: "10px", marginTop: "10px", color: "white" }}
              block
              color={"info"}
              onClick={() => {
                onStageChange(EMBED_STAGE.EXPERT_VIDEO);
              }}
            >
              See expert video
            </Button>

            <Button
              style={{ marginBottom: "10px", marginTop: "10px", color: "white" }}
              block
              color={"info"}
              onClick={() => {
                onAnalysisResponse?.(null);
                onStageChange(EMBED_STAGE.INTERACTION_VIDEO);
              }}
            >
              Try again
            </Button>

            <Button
              // TODO: these styles are shared, could be extracted to a constant.
              style={{ marginBottom: "10px", marginTop: "10px", color: "white" }}
              block
              color={"info"}
              onClick={() => {
                onStageChange(null);
              }}
            >
              {isEmbedded ? "Quit" : "To Quit, please close the active tab"}
            </Button>
          </div>
        </Column>

        <Column>
          <h3 style={{ color: headerColor }}>Suggestions</h3>
          {parsedAbilities.map(({ name, description, points, feedback, numRequired, priority }) => (
            <AnalysisCard>
              <h4 style={{ color: headerColor }}>{name}</h4>
              <p style={{ color: "gray", marginLeft: "20px" }}>
                {getAbilityStatusText(name, priority, parsedAbilities.length, numRequired)}
              </p>
              <p>{description}</p>

              <div style={{ margin: "10px 0" }}>
                {feedback.map(({ feedback, isPositive }) => {
                  const icon = isPositive ? "icon-plus" : "icon-minus";
                  const color = isPositive ? "text-success" : "text-danger";
                  return (
                    <span className={color}>
                      {" "}
                      <i className={icon} /> {feedback}
                      <br />
                    </span>
                  );
                })}
              </div>

              <p style={{ textAlign: "right" }} className={points ? "text-success" : "text-danger"}>
                {points} points
              </p>
            </AnalysisCard>
          ))}
        </Column>
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "100%",
        height: "100%",
        minHeight: "100%",
        maxWidth: "100%",
      }}
    >
      {content}
    </div>
  );
};

export default AnalysisScreen;
