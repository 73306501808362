import React from "react";
import { Button } from "reactstrap";
import { IFX_SUPPORT_EMAIL, IS_PROD } from "../constants";
import { HOME_URL } from "../constants/urls";

type ErrorBoundaryProps = {};
type ErrorBoundaryState = {
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
};

const ERROR_SUBJECT = encodeURI("Admin Dashboard Error");

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ error, errorInfo });
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error) {
      return (
        <div>
          <h1>Oh no!</h1>
          <div>
            We've encountered an unexpected error. Please return to the home page, if the problem
            continues, please contact{" "}
            <a href={`mailto:${IFX_SUPPORT_EMAIL}?subject=${ERROR_SUBJECT}`}>
              Interflexion Support
            </a>
            .
            <br />
            <Button
              color="primary"
              title="Reload page"
              onClick={() => window.location.assign(HOME_URL)}
            >
              Go Home
            </Button>
          </div>

          {!IS_PROD && (
            <div style={{ marginTop: "5rem" }}>
              <strong>Error Details:</strong>
              <pre>{error.message}</pre>
            </div>
          )}
        </div>
      );
    }

    return children;
  }
}
