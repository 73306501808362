import React from "react";
import { Redirect, Route, RouteProps, matchPath, useHistory, useLocation } from "react-router-dom";
import { IfxPrivilegeName } from "../constants/types";
import {
  TOKEN_EMBED_URL,
  LOGIN_URL,
  CREDENTIALS_EMBED_URL,
  TOKEN_INTERACT_URL,
  CREDENTIALS_INTERACT_URL, 
} from "../constants/urls";
import { useAuth } from "../contexts/authUser";
import { getNextUrl, useHasAuthorization } from "../helpers/authorization";
import { useHasPermission, UserPermission } from "../helpers/permissions";
import { makeQueryString } from "../helpers/urls";
import CredentialsEmbedPage from "../views/Pages/Embed/Credentials/CredentialsEmbed";
import EmbedPage from "../views/Pages/Embed/EmbedPage";
import TokenEmbedPage from "../views/Pages/Embed/Token/TokenEmbed";

export type AuthorizedRouteProps = RouteProps & {
  lazyComponent?: React.LazyExoticComponent<any>;
  redirectTo?: string;
  requiredPermissions?: UserPermission[];
  requiredPrivileges?: IfxPrivilegeName[];
};

type UseCanAccessRouteParams = {
  requiredPermissions?: UserPermission[];
  requiredPrivileges?: IfxPrivilegeName[];
};

function useHasAuthorizationAndPermission({
  requiredPermissions = [],
  requiredPrivileges = [],
}: UseCanAccessRouteParams) {
  const hasAuthorization = useHasAuthorization(requiredPrivileges);
  const hasPermission = useHasPermission(requiredPermissions);

  return {
    hasAuthorization,
    hasPermission: requiredPermissions.length ? hasPermission : hasAuthorization,
  };
}

export function AuthorizedRoute({
  children,
  component,
  lazyComponent: LazyComponent,
  exact,
  path,
  redirectTo = LOGIN_URL,
  requiredPrivileges,
  requiredPermissions,
  ...props
}: AuthorizedRouteProps) {
  const { hasAuthorization, hasPermission } = useHasAuthorizationAndPermission({
    requiredPrivileges,
    requiredPermissions,
  });
  const history = useHistory();
  const location = useLocation();
  const auth = useAuth();

  function getChildren() {
    // The embed screen authenticates itself
    const tokenEmbedMatch = matchPath(window.location.pathname, {
      path: [TOKEN_EMBED_URL, TOKEN_INTERACT_URL],
      exact: true,
      strict: false,
    });

    if (tokenEmbedMatch) {
      return <TokenEmbedPage />;
    }

    // const credentialsEmbedMatch = matchPath(window.location.pathname, {
    //   path: [CREDENTIALS_EMBED_URL, CREDENTIALS_INTERACT_URL],
    //   exact: true,
    //   strict: false,
    // });

    const credentialsEmbedMatch = matchPath(window.location.pathname, {
      path: [CREDENTIALS_EMBED_URL],
      exact: true,
      strict: false,
    });

    if (credentialsEmbedMatch) {
      return <EmbedPage />;
    }

    const credentialsInteractMatch = matchPath(window.location.pathname, {
      path: [CREDENTIALS_INTERACT_URL],
      exact: true,
      strict: false,
    });

    if (credentialsInteractMatch) {
      return <CredentialsEmbedPage />;
    }

    if (hasAuthorization) {
      if (hasPermission) {
        if (LazyComponent) return <LazyComponent />;
        if (component) return component;
        if (children) return children;
      }

      // if the user does not have permissions on this page, bounce back
      // TODO: add toast or flash message for this
      history.goBack();
      return null;
    }

    // Get the path we should redirect back to on successful login
    const pathString = Array.isArray(path) ? path[0] : path;
    const next = getNextUrl({
      isUserAuthenticated: !!auth.authUser,
      nextPath: location?.pathname || pathString,
    });

    return (
      <Redirect
        from={path as string}
        to={{
          pathname: redirectTo,
          search: makeQueryString({ next }),
        }}
      />
    );
  }

  return (
    <Route exact={exact} path={path} {...props}>
      {getChildren()}
    </Route>
  );
}
