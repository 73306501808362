import { initializeApp } from "firebase/app";
import {
  browserLocalPersistence,
  confirmPasswordReset,
  connectAuthEmulator,
  createUserWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  setPersistence,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
  signInWithCustomToken,
  verifyPasswordResetCode,
} from "firebase/auth";
import {
  FirebaseStorage,
  getStorage,
  getDownloadURL,
  ref,
  uploadBytes,
  connectStorageEmulator,
} from "firebase/storage";
import {
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_DATABASE_URL,
  FIREBASE_LOCAL_AUTH_EMULATOR_URL,
  FIREBASE_MEASUREMENT_ID,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  IS_LOCAL,
  apiRoot,
  functionRoot,
  isProd,
  FIREBASE_LOCAL_STORAGE_EMULATOR_URL,
} from "../../constants";
import { ParametersExceptFirst } from "../../helpers/types";

const firebaseApp = initializeApp({
  apiKey: FIREBASE_API_KEY,
  appId: FIREBASE_APP_ID,
  projectId: FIREBASE_PROJECT_ID,
  authDomain: FIREBASE_AUTH_DOMAIN,
  databaseURL: FIREBASE_DATABASE_URL,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  measurementId: FIREBASE_MEASUREMENT_ID,
});

const auth = getAuth(firebaseApp);
setPersistence(auth, browserLocalPersistence);

type IfxStorageRefArgs = ParametersExceptFirst<typeof ref>;

const rawStorage = getStorage(firebaseApp);
const getStorageRef = (...args: IfxStorageRefArgs) => ref(rawStorage, ...args);

if (IS_LOCAL) {
  console.warn("LOCAL DEVELOPMENT MODE");
  connectAuthEmulator(auth, FIREBASE_LOCAL_AUTH_EMULATOR_URL);
  connectStorageEmulator(rawStorage, FIREBASE_LOCAL_STORAGE_EMULATOR_URL, 9199);
}

type EnhancedFirebaseStorage = FirebaseStorage & {
  ref: typeof getStorageRef;
  getDownloadURLForRef: typeof getDownloadURL;
  getDownloadURLForPath: (...args: IfxStorageRefArgs) => Promise<string>;
  uploadBytes: typeof uploadBytes;
};

const storage: EnhancedFirebaseStorage = {
  ...rawStorage,
  ref: getStorageRef,
  getDownloadURLForRef: getDownloadURL,
  getDownloadURLForPath: (...args: IfxStorageRefArgs) => getDownloadURL(getStorageRef(...args)),
  uploadBytes,
};

const Firebase = {
  auth,
  storage,
  isProd,
  apiRoot,
  functionRoot,
  doCreateUserWithEmailAndPassword: createUserWithEmailAndPassword.bind(null, auth),
  doSignInWithEmailAndPassword: signInWithEmailAndPassword.bind(null, auth),
  doSignInWithCustomToken: signInWithCustomToken.bind(null, auth),
  doSignOut: signOut.bind(null, auth),
  doPasswordReset: sendPasswordResetEmail.bind(null, auth),
  doPasswordUpdate: (newPassword: Parameters<typeof updatePassword>[1]) => {
    if (!auth.currentUser) {
      throw new Error("You must be logged in to do that.");
    }

    return updatePassword(auth.currentUser, newPassword);
  },
  doConfirmPasswordReset: confirmPasswordReset.bind(null, auth),
  doVerifyResetToken: verifyPasswordResetCode.bind(null, auth),
};

export default Firebase;
