import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { apiRoot, functionRoot, embedRoot } from "../constants";

export interface IfxFunctionsClient extends AxiosInstance {
  get<T, D = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig<D>): Promise<R>;
  patch<T, D = any, R = AxiosResponse<T>>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ): Promise<R>;
  post<T, D = any, R = AxiosResponse<T>>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ): Promise<R>;
  put<T, D = any, R = AxiosResponse<T>>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>
  ): Promise<R>;
}

const commonAxiosConfig: AxiosRequestConfig = {
  baseURL: functionRoot,
};

/**
 * Clients for communicating with the IFX backend (firebase functions)
 */
export const unauthorizedClient: IfxFunctionsClient = axios.create(commonAxiosConfig);

export const ifxApiClient: IfxFunctionsClient = axios.create({
  ...commonAxiosConfig,
  baseURL: apiRoot,
});

export const embedApiClient: IfxFunctionsClient = axios.create({
  ...commonAxiosConfig,
  baseURL: embedRoot,
});
