// https://stackoverflow.com/a/46700791
export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

// Applies NonNullable to the values of an interface/type/record
export type NonNullableValues<T> = {
  [K in keyof T]: NonNullable<T[K]>;
};

export type ParametersExceptFirst<F> = F extends (arg0: any, ...rest: infer R) => any ? R : never;

type PickByValue<T, Value> = Pick<T, { [K in keyof T]: T[K] extends Value ? K : never }[keyof T]>;

// From https://stackoverflow.com/a/60142095
type ObjectEntries<T> = {
  [K in keyof T]: [keyof PickByValue<T, T[K]>, T[K]];
}[keyof T][];

// typed variant of Object.entries
export function getTypedObjectEntries<T extends Record<string, unknown>>(obj: T) {
  return Object.entries(obj) as ObjectEntries<T>;
}
