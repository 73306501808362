import React, { FC, useEffect, useRef } from 'react';

interface VideoPreviewProps {
  stream: MediaStream | null;
}

const VideoPreview: FC<VideoPreviewProps> = ({ stream }) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const startPlayback = () => {
    if (videoRef.current && stream) {
      if (videoRef.current.paused) {
        videoRef.current.srcObject = stream;
        videoRef.current.play().catch((error) => {
          console.error('Failed to start video playback:', error);
        });
      }
    }
  };

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);

  return (
    <div className="video-preview">
      {stream && (
        <>
          <video onClick={startPlayback} ref={videoRef} width={500} height={500} autoPlay muted playsInline />
        </>
      )}
      {!stream && <p>No media stream available</p>}
    </div>
  );
};

export default VideoPreview;
