import React from "react";
import { Button } from "reactstrap";

export enum EMBED_STAGE {
  AUTHENTICATION,
  INTERACTION_VIDEO,
  RESPONSE_VIDEO_RECORDING,
  ANALYSIS,
  EXPERT_VIDEO,
}

interface EmbedButtonProps {
  onClick?: () => void;
  children: React.ReactNode;
}

export const EmbedButton = ({ onClick, children }: EmbedButtonProps) => (
  <Button
    style={{
      marginBottom: "10px",
      marginTop: "10px",
      color: "white",
      maxWidth: "430px",
    }}
    block
    color="info"
    onClick={() => {
      onClick?.();
    }}
  >
    {children}
  </Button>
);
