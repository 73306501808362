import React, { useContext } from "react";
import Firebase from "./firebase";

export type FirebaseContextShape = typeof Firebase;

export const FirebaseContext = React.createContext<FirebaseContextShape>(Firebase);

export const useFirebase = () => useContext(FirebaseContext);

export function FirebaseProvider({ children }: { children: React.ReactNode }) {
  return <FirebaseContext.Provider value={Firebase}>{children}</FirebaseContext.Provider>;
}

type PropsWithFirebase<P> = P & { firebase: typeof Firebase };

export const withFirebase =
  <P extends Record<string, unknown>>(Component: React.ComponentType<PropsWithFirebase<P>>) =>
  (props: P) =>
    (
      <FirebaseContext.Consumer>
        {firebase => <Component {...props} firebase={firebase} />}
      </FirebaseContext.Consumer>
    );
