import cx from "classnames";
import React from "react";

export type LoadingProps = {
  className?: string;
  children?: React.ReactNode;
};

export function Loading({ className, children = "Loading…" }: LoadingProps) {
  return <div className={cx("animated fadeIn pt-3 text-center", className)}>{children}</div>;
}
