import "react-app-polyfill/stable";
import "./polyfill";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { AuthProvider } from "./contexts/authUser";
import { FirebaseProvider } from "./contexts/firebase";
import * as serviceWorker from "./serviceWorker";
import "./index.css";

const queryClient = new QueryClient();

ReactDOM.render(
  <ErrorBoundary>
    <QueryClientProvider client={queryClient}>
      <FirebaseProvider>
        <AuthProvider>
          <App />
        </AuthProvider>
      </FirebaseProvider>
    </QueryClientProvider>
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
