import { ROOT_URL } from "../constants/urls";
import { useAuth } from "../contexts/authUser";

export function hasAuthorization(userPrivileges?: string[], requiredPrivileges?: string[]) {
  // if there's no user privileges, they aren't authorized for anything
  if (!userPrivileges?.length) return false;

  // if we don't have a list of required privileges, we only require
  // that they are logged in
  if (!requiredPrivileges?.length) return true;

  return userPrivileges.some(userPrivilege => requiredPrivileges.includes(userPrivilege));
}

export function useHasAuthorization(requiredPrivileges?: string[]) {
  const auth = useAuth();
  return hasAuthorization(auth.authUser?.privileges, requiredPrivileges);
}

/**
 * Returns the next URL of an authorized route to redrect to after login
 * @param param0
 * @returns
 */
export const getNextUrl = ({
  isUserAuthenticated,
  nextPath,
}: {
  isUserAuthenticated: boolean;
  nextPath?: string;
}): string | undefined => {
  if (!isUserAuthenticated && nextPath && nextPath !== ROOT_URL) {
    // if the user is authenticated, it is probably a permission issue;
    // not checking this will redirect to login with a <next> path, Login will check that the user
    //   is authenticated, will redirect to <next> and a loop will be formed
    return nextPath;
  }

  return undefined;
};
