import React, { useCallback, useEffect, useState } from "react";
import { matchPath, useHistory } from "react-router-dom";
import AnalysisScreen from "../../../../components/Interact/AnalysisScreen";
import RecordScreen from "../../../../components/Interact/RecordScreen";
import VideoScreen from "../../../../components/Interact/VideoScreen";
import { TOKEN_EMBED_URL, LOGIN_URL, TOKEN_INTERACT_URL } from "../../../../constants/urls";
import { useAuth } from "../../../../contexts/authUser";
import { useFirebase } from "../../../../contexts/firebase";
import { embedApiClient, ifxApiClient } from "../../../../helpers/api";
import { EMBED_STAGE, EmbedButton } from "../common";
import { matchInteractPath } from "../utils";

function TokenEmbedPage() {
  const [embedStage, setEmbedStage] = useState(EMBED_STAGE.AUTHENTICATION);
  const [interactionVideoUrl, setInteractionVideoUrl] = useState<null | string>(null);
  const [expertVideoUrl, setExpertVideoUrl] = useState<null | string>(null);
  const [firebaseVideoUrl, setFirebaseVideoUrl] = useState<string | undefined>();
  const [analysisResponse, setAnalysisResponse] = useState<any>(null);
  const history = useHistory();
  const [isVideoPaused, setIsVideoPaused] = useState<boolean | undefined>();
  const [isEmbedded, setIsEmbedded] = useState(false);

  const auth = useAuth();
  const firebase = useFirebase();
  const embedMatch = matchInteractPath<{ storyId: string; sceneId: string; userId: string }>(
    TOKEN_EMBED_URL
  );
  const interactMatch = matchInteractPath<{ storyId: string; sceneId: string; userId: string }>(
    TOKEN_INTERACT_URL
  );
  const pathMatch = embedMatch || interactMatch;

  useEffect(() => {
    if (!!embedMatch) {
      setIsEmbedded(true);
    }
  }, [embedMatch]);

  useEffect(() => {
    if (auth.authUser && embedStage === EMBED_STAGE.AUTHENTICATION) {
      setEmbedStage(EMBED_STAGE.INTERACTION_VIDEO);
    }
  }, [auth.authUser, embedStage]);

  useEffect(() => {
    // So the user gives webcam permission from the beginning, so when we reach the recording screen, it's already approved.
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then(stream => {
        stream.getTracks().forEach(x => x.stop());
      })
      .catch(err => {
        console.error(err);
      });
  }, []);

  const loadScene = useCallback(() => {
    if (!auth.authUser) {
      return;
    }
    ifxApiClient
      // TODO: .get<ResponseShape>
      .get(`scenes/${pathMatch?.params.sceneId}`)
      .then(sceneResponse => {
        const data = sceneResponse.data as Record<string, unknown>;

        const videos = data.videos as Record<string, string>;
        let interactionFileName = videos.interactionVideoName + "." + videos.interactionVideoType;
        firebase.storage.getDownloadURLForPath(interactionFileName).then(url => {
          setInteractionVideoUrl(url);
        });

        let expertFileName = videos.professionalVideoName + "." + videos.professionalVideoType;
        firebase.storage.getDownloadURLForPath(expertFileName).then(url => {
          setExpertVideoUrl(url);
        });
      })
      .catch(err => console.error(err));
  }, [auth.authUser, firebase.storage, pathMatch?.params.sceneId]);

  useEffect(() => {
    async function getTokenAndLoadScene() {
      if (auth.authUser) {
        await auth.authUser?.getIdToken(true); // for some reason, if I don't add this line, the scene GET does not have the token in it

        loadScene();
      }
    }

    getTokenAndLoadScene();
  }, [auth.authUser, loadScene]);

  const getEmbedToken = useCallback(() => {
    return embedApiClient
      .get(`token/${pathMatch?.params.userId}`)
      .then(response => {
        return (response.data as { token: string }).token;
      })
      .catch(error => {
        console.error(error);
      });
  }, [pathMatch?.params.userId]);

  const isLoading =
    embedStage !== EMBED_STAGE.AUTHENTICATION && (!interactionVideoUrl || !expertVideoUrl);

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        overflow: "scroll",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "5px solid #bfefff",
        borderRadius: 10,
        padding: "20px",
      }}
    >
      {isLoading && <h5>Loading&hellip;</h5>}

      {embedStage === EMBED_STAGE.AUTHENTICATION && (
        <EmbedButton
          onClick={async () => {
            const token = await getEmbedToken();
            if (token) {
              await firebase.doSignInWithCustomToken(token);
            }

            setEmbedStage(EMBED_STAGE.INTERACTION_VIDEO);
          }}
        >
          Start
        </EmbedButton>
      )}

      {embedStage === EMBED_STAGE.INTERACTION_VIDEO && interactionVideoUrl && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <VideoScreen
            videoUrl={interactionVideoUrl}
            autoPlay
            paused={isVideoPaused}
            onVideoEndOrPaused={() => {
              setIsVideoPaused(true);
            }}
            onVideoPlay={() => {
              setIsVideoPaused(false);
            }}
          />

          <EmbedButton
            onClick={() => {
              setIsVideoPaused(prev => !prev);
            }}
          >
            {isVideoPaused ? "Listen" : "Pause"}
          </EmbedButton>

          <EmbedButton
            onClick={() => {
              setEmbedStage(EMBED_STAGE.RESPONSE_VIDEO_RECORDING);
            }}
          >
            Respond
          </EmbedButton>
        </div>
      )}
      {embedStage === EMBED_STAGE.EXPERT_VIDEO && expertVideoUrl && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h5 style={{ display: "inline-block" }}>Expert video</h5>
          <VideoScreen videoUrl={expertVideoUrl} />
          <EmbedButton
            onClick={() => {
              setAnalysisResponse(null);
              setEmbedStage(EMBED_STAGE.INTERACTION_VIDEO);
            }}
          >
            Try again
          </EmbedButton>

          <EmbedButton
            onClick={() => {
              setEmbedStage(EMBED_STAGE.ANALYSIS);
            }}
          >
            Go back to feedback
          </EmbedButton>
        </div>
      )}

      {embedStage === EMBED_STAGE.RESPONSE_VIDEO_RECORDING && (
        <RecordScreen
          onAnalyze={videoUrl => {
            setFirebaseVideoUrl(videoUrl);
            setEmbedStage(EMBED_STAGE.ANALYSIS);
          }}
        />
      )}

      {embedStage === EMBED_STAGE.ANALYSIS && (
        <AnalysisScreen
          // TODO: show error if those are not set
          storyId={pathMatch?.params.storyId || ""}
          sceneId={pathMatch?.params.sceneId || ""}
          userId={pathMatch?.params.userId || ""}
          videoUrl={firebaseVideoUrl}
          onStageChange={async stage => {
            if (stage) {
              setEmbedStage(stage);
            } else {
              await firebase.doSignOut();
              history.push(LOGIN_URL);
            }
          }}
          analysisResponse={analysisResponse}
          onAnalysisResponse={setAnalysisResponse}
          isEmbedded={isEmbedded}
        />
      )}
    </div>
  );
}

export default TokenEmbedPage;
